import Vue            from 'vue'
import Router         from 'vue-router'

Vue.use(Router)

export const routerMap = [
    {
        path     : '/',
        redirect : '/dashboard',
        hidden   : true,
    },
    {
        path : '/dashboard',
        name : 'dashboard',
        component: () => import('@/views/Dashboard'),
        hidden   : false,
        meta: {
            title: 'Dashboard',
        }
    },
    {
        path: '/site',
        name : 'site',
        meta: {
            title: 'Управление сайтом',
            roles: ['admin', 'marketolog', 'smm'],
            icon: 'documentation'
        }
    },
    {
        path: '/site/pages',
        name : 'site_pages',
        component: () => import('@/views/Site/Pages'),
        meta: {
            title: 'Страницы',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/lkpages',
        name : 'site_lkpages',
        component: () => import('@/views/Site/LkPages'),
        meta: {
            title: 'Страницы ЛК',
            roles: ['admin'],
            parent: 'site'
        }
    },
    {
        path: '/site/landings',
        name : 'site_landings',
        component: () => import('@/views/Site/Landings'),
        meta: {
            title: 'Лендинги',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/promosliders',
        name : 'site_promosliders',
        component: () => import('@/views/Site/Landings/Slides'),
        meta: {
            title: 'Cлайды для промо-лендингов',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/promosliders/:id',
        name : 'site_promosliders_item',
        component: () => import('@/views/Site/Landings/Slides/SetPage'),
        meta: {
            title: 'Cлайды',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        },
        hidden   : true,
    },
    {
        path: '/site/promobanners',
        name : 'site_promobanners',
        component: () => import('@/views/Site/PromoBanners'),
        meta: {
            title: 'Промо Акции',
            roles: ['admin', 'site'],
            parent: 'site'
        }
    },
    {
        path: '/site/solutions',
        name : 'site_solutions',
        component: () => import('@/views/Site/Solutions'),
        meta: {
            title: 'Решения на главной',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/banners',
        name : 'site_banners',
        component: () => import('@/views/Site/Banners'),
        meta: {
            title: 'Баннеры на главной',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/articles',
        name : 'site_articles',
        component: () => import('@/views/Site/Articles'),
        meta: {
            title: 'Статьи',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/news',
        name : 'site_news',
        component: () => import('@/views/Site/News'),
        meta: {
            title: 'Новости',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/blog',
        name : 'site_blog',
        component: () => import('@/views/Site/Blog'),
        meta: {
            title: 'Блог',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/tags',
        name : 'site_tags',
        component: () => import('@/views/Site/Tags'),
        meta: {
            title: 'Теги на сайте',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/contacts',
        name : 'site_contacts',
        component: () => import('@/views/Site/Contacts'),
        meta: {
            title: 'Контакты',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/guarantees',
        name : 'site_guarantees',
        component: () => import('@/views/Site/Garantees'),
        meta: {
            title: 'Гарантии',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },
    {
        path: '/site/texts',
        name : 'site_texts',
        component: () => import('@/views/Site/TemplateTexts'),
        meta: {
            title: 'Тексты шаблонов',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'site'
        }
    },{
        path: '/site/categories',
        name : 'site_categories',
        component: () => import('@/views/Site/Categories'),
        meta: {
            title: 'Категории каталога',
            roles: ['admin'],
            parent: 'site'
        }
    },
    
    {
        path: '/pim',
        name : 'pim',
        meta: {
            title: 'Каталог',
            roles: ['admin', '1c', 'pim_editor', 'pim_moderator','marketolog'],
            icon: 'documentation'
        }
    },{
        path: '/catalog/category',
        name : 'catalog_category',
        component: () => import('@/views/Catalog/Category'),
        meta: {
            title: 'Категории',
            roles: ['admin'],
            parent: 'pim'
        }
    },{
        path: '/catalog/series',
        name : 'catalog_series',
        component: () => import('@/views/Catalog/Series'),
        meta: {
            title: 'Модельные ряды',
            roles: ['admin'],
            parent: 'pim'
        }
    },
    {
        path: '/catalog/products',
        name : 'catalog_products',
        component: () => import('@/views/Pim/Product'),
        meta: {
            title: 'Товары',
            roles: ['admin', '1c', 'pim_editor', 'pim_moderator'],
            parent: 'pim'
        }
    },{
        path: '/catalog/products/:id',
        name : 'catalog_products_item',
        component: () => import('@/views/Pim/Product/ProductPage'),
        meta: {
            title: 'Товары',
            roles: ['admin', '1c', 'pim_editor', 'pim_moderator'],
            parent: 'pim'
        },
        hidden   : true,
    },{
        path: '/catalog/market-launch',
        name : 'catalog_market_launch',
        component: () => import('@/views/Pim/MarketLaunch'),
        meta: {
            title: 'Вывод нового товара на рынок',
            roles: ['admin', 'pim_editor','pim_moderator','marketolog'],
            parent: 'pim'
        }
    },{
        path: '/pim/addproducts',
        name : 'pim_addproducts',
        component: () => import('@/views/Pim/NewProducts'),
        meta: {
            title: 'Добавление товаров',
            roles: ['admin', 'pim_moderator'],
            parent: 'pim'
        }
    },{
        path: '/pim/addproducts/:id',
        name : 'pim_addproducts_item',
        component: () => import('@/views/Pim/NewProducts'),
        meta: {
            title: 'Добавление товаров',
            roles: ['admin', 'pim_moderator'],
            parent: 'pim'
        },
        hidden   : true,
    },{
        path: '/pim/hierarchy',
        name : 'pim_hierarchy',
        component: () => import('@/views/Pim/Ierarchy'),
        meta: {
            title: 'Иерархия товаров',
            roles: ['admin','pim_editor', 'pim_moderator'],
            parent: 'pim'
        }
    },{
        path: '/pim/presets',
        name : 'pim_presets',
        component: () => import('@/views/Pim/Presets'),
        meta: {
            title: 'Пресеты товаров',
            roles: ['admin','pim_editor', 'pim_moderator'],
            parent: 'pim'
        }
    },{
        path: '/pim/comments',
        name : 'pim_comments',
        component: () => import('@/views/Pim/Comments'),
        meta: {
            title: 'Комментарии к товарам',
            roles: ['admin'],
            parent: 'pim'
        }
    },{
        path: '/pim/warehouse',
        name : 'pim_warehouse',
        component: () => import('@/views/Pim/Warehouse'),
        meta: {
            title: 'Склады',
            roles: ['admin'],
            parent: 'pim'
        }
    },{
        path: '/pim/emulate_1c',
        name : 'pim_emulate_1c',
        component: () => import('@/views/Pim/Emulate1C'),
        meta: {
            title: 'Эмуляция отправки из 1С',
            roles: ['admin'],
            parent: 'pim',
            testonly: true,
        }
    },

    {
        path: '/catalog',
        name : 'catalog',
        meta: {
            title: 'Данные для Каталога',
            roles: ['admin', 'marketolog','tech_expert', 'smm'],
            icon: 'documentation'
        }
    },
    
    {
        path: '/catalog/pictogram',
        name : 'catalog_pictogram',
        component: () => import('@/views/Catalog/Pictograms'),
        meta: {
            title: 'Пиктограммы',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },
    {
        path: '/catalog/application_images',
        name : 'catalog_application_images',
        component: () => import('@/views/Catalog/ApplicationImages'),
        meta: {
            title: 'Изображения применения',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },
    {
        path: '/catalog/icons',
        name : 'catalog_icons',
        component: () => import('@/views/Catalog/ProductIcons'),
        meta: {
            title: 'Иконки',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },
    {
        path: '/catalog/parameters',
        name : 'catalog_parameters',
        component: () => import('@/views/Catalog/ProductParameters'),
        meta: {
            title: 'Характеристики товаров',
            roles: ['admin', 'marketolog','tech_expert', 'smm'],
            parent: 'catalog'
        }
    },
    {
        path: '/catalog/filters',
        name : 'catalog_filters',
        component: () => import('@/views/Catalog/CategoryFilters'),
        meta: {
            title: 'Фильтры категорий',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },
    {
        path: '/catalog/tags',
        name : 'catalog_tags',
        component: () => import('@/views/Catalog/Tags'),
        meta: {
            title: 'Теги каталога',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },{
        path: '/catalog/garantee',
        name : 'catalog_garantee',
        component: () => import('@/views/Catalog/Garantees'),
        meta: {
            title: 'Правила гарантии на товары',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },{
        path: '/catalog/texts',
        name : 'catalog_texts',
        component: () => import('@/views/Catalog/Texts'),
        meta: {
            title: 'Тексты страницы товара',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'catalog'
        }
    },

    {
        path: '/parking',
        name : 'parking',
        meta: {
            title: 'Парковки',
            roles: ['admin'],
            icon: 'documentation'
        }
    },
    {
        path: '/parking/components',
        name : 'parking_components',
        component: () => import('@/views/Parking/Components'),
        meta: {
            title: 'Компоненты парковок',
            roles: ['admin'],
            parent: 'parking'
        }
    },
    {
        path: '/parking/parameters',
        name : 'parking_parameters',
        component: () => import('@/views/Parking/Parameters'),
        meta: {
            title: 'Характеристики парковок',
            roles: ['admin'],
            parent: 'parking'
        }
    },

    {
        path: '/pricelists',
        name : 'pricelists',
        meta: {
            title: 'Прайс-листы',
            roles: ['admin','marketolog', 'smm'],
            icon: 'documentation'
        }
    },
    {
        path: '/pricelists/pricelists',
        name : 'pricelists_pricelists',
        component: () => import('@/views/Pricelists/DynamicPricelists'),
        meta: {
            title: 'Динамические прайс-листы',
            roles: ['admin','marketolog', 'smm'],
            parent: 'pricelists'
        }
    },
    {
        path: '/pricelists/pricelists/:id',
        name : 'pricelists_pricelists_item',
        component: () => import('@/views/Pricelists/DynamicPricelists/PricelistPage'),
        meta: {
            title: 'Прайс-лист',
            roles: ['admin','marketolog', 'smm'],
            parent: 'pricelists'
        },
        hidden   : true,
    },
    // {
    //     path: '/pricelists/passwords',
    //     name : 'pricelists_passwords',
    //     component: () => import('@/views/Pricelists/Passwords'),
    //     meta: {
    //         title: 'Пароли прайс-листов',
    //         roles: ['admin','marketolog', 'smm'],
    //         parent: 'pricelists'
    //     }
    // },
    {
        path: '/pricelists/files',
        name : 'pricelists_files',
        component: () => import('@/views/Support/Other/Pricelists'),
        meta: {
            title: 'Загружаемые прайс-листы',
            roles: ['admin'],
            parent: 'pricelists'
        }
    },
    {
        path: '/kp',
        name : 'kp',
        meta: {
            title: 'Коммерческие предложения',
            roles: ['admin'],
            icon: 'documentation'
        }
    },
    {
        path: '/kp/settings',
        name : 'kp_settings',
        component: () => import('@/views/Kp/KpParkingSettings'),
        meta: {
            title: 'Настройки КП Парковок',
            roles: ['admin'],
            parent: 'kp'
        }
    },
    {
        path: '/kp/check',
        name : 'kp_check',
        component: () => import('@/views/Kp/KpParkingCheck'),
        meta: {
            title: 'Проверка подбора КП Парковок',
            roles: ['admin', 'pim_moderator'],
            parent: 'kp'
        }
    },
    {
        path: '/kp/kp_parking',
        name : 'kp_parking',
        component: () => import('@/views/Kp/KpParking'),
        meta: {
            title: 'КП Парковок партнеров',
            roles: ['admin'],
            parent: 'kp'
        }
    },

    {
        path: '/about',
        name : 'about',
        meta: {
            title: 'О компании',
            roles: ['admin', 'marketolog', 'smm'],
            icon: 'documentation'
        }
    },
    {
        path: '/about/blocks',
        name : 'about_blocks',
        component: () => import('@/views/Site/About/Blocks'),
        meta: {
            title: 'Текстовые блоки',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'about'
        }
    },
    {
        path: '/about/laws',
        name : 'about_laws',
        component: () => import('@/views/Site/About/Laws'),
        meta: {
            title: 'Законы автоматизации',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'about'
        }
    },

    {
        path: '/projects',
        name : 'projects',
        meta: {
            title: 'Проекты',
            roles: ['admin', 'marketolog', 'smm'],
            icon: 'documentation'
        }
    },
    {
        path: '/projects/projects',
        name : 'projects_projects',
        component: () => import('@/views/Projects/Projects'),
        meta: {
            title: 'Проекты',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'projects'
        }
    },{
        path: '/projects/projects/:id',
        name : 'projects_projects_item',
        component: () => import('@/views/Projects/Projects/ProjectPage'),
        meta: {
            title: 'Проекты',
            roles: ['admin', 'marketolog'],
            parent: 'projects'
        },
        hidden   : true,
    },
    {
        path: '/projects/types',
        name : 'projects_types',
        component: () => import('@/views/Projects/Types'),
        meta: {
            title: 'Типы проектов',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'projects'
        }
    },
    {
        path: '/projects/markets',
        name : 'projects_markets',
        component: () => import('@/views/Projects/Markets'),
        meta: {
            title: 'Маркеты проектов (не исп.)',
            roles: ['admin'],
            parent: 'projects'
        }
    },
    {
        path: '/projects/zones',
        name : 'projects_zones',
        component: () => import('@/views/Projects/Zones'),
        meta: {
            title: 'Зоны проектов (не исп.)',
            roles: ['admin'],
            parent: 'projects'
        }
    },

    {
        path: '/dealers',
        name : 'dealers',
        meta: {
            title: 'Дилеры',
            roles: ['admin', 'marketolog', 'director_op'],
            icon: 'documentation'
        }
    },
    {
        path: '/dealers/dealers/:id',
        name : 'dealers_dealers_item',
        component: () => import('@/views/Dealers/Dealers/DealerPage'),
        meta: {
            title: 'Партнеры',
            roles: ['admin', 'director_op'],
            parent: 'dealers'
        },
        hidden   : true,
    },
    {
        path: '/dealers/dealers',
        name : 'dealers_dealers',
        component: () => import('@/views/Dealers/Dealers'),
        meta: {
            title: 'Компании',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },{
        path: '/dealers/new-dealers',
        name : 'dealers_new_dealers',
        component: () => import('@/views/Dealers/NewDealers'),
        meta: {
            title: 'Новые компании на модерации',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },
    {
        path: '/dealers/types',
        name : 'dealers_types',
        component: () => import('@/views/Dealers/Types'),
        meta: {
            title: 'Типы дилеров',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },
    {
        path: '/dealers/services',
        name : 'dealers_services',
        component: () => import('@/views/Dealers/Services'),
        meta: {
            title: 'Услуги дилеров',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },
    {
        path: '/dealers/expanded_services',
        name : 'dealers_expanded_services',
        component: () => import('@/views/Dealers/ExpandedServices'),
        meta: {
            title: 'Расширенные услуги дилеров',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },
    {
        path: '/dealers/payment_types',
        name : 'dealers_payment_types',
        component: () => import('@/views/Dealers/PaymentTypes'),
        meta: {
            title: 'Варианты расчетов',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },{
        path: '/dealers/cities',
        name : 'dealers_cities',
        component: () => import('@/views/Dealers/Cities'),
        meta: {
            title: 'Города дилеров',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },{
        path: '/dealers/regions',
        name : 'dealers_regions',
        component: () => import('@/views/Dealers/Regions'),
        meta: {
            title: 'Регионы работы дилеров',
            roles: ['admin', 'marketolog', 'director_op'],
            parent: 'dealers'
        }
    },
    // {
    //     path: '/dealers/db_cities',
    //     name : 'dealers_db_cities',
    //     component: () => import('@/views/Dealers/DbCities'),
    //     meta: {
    //         title: 'Координаты городов',
    //         roles: ['admin', 'marketolog'],
    //         parent: 'dealers'
    //     }
    // },

    {
        path: '/oxilor_cities',
        name : 'oxilor_cities',
        component: () => import('@/views/OxilorCities'),
        meta: {
            title: 'Города Oxilor',
            roles: ['admin'],
            icon: 'documentation'
        }
    },

    {
        path: '/support',
        name : 'support',
        meta: {
            title: 'Поддержка',
            roles: ['admin', 'pim_editor', 'pim_moderator', '1c', 'tech_expert'],
            icon: 'documentation'
        }
    },
    {
        path: '/support/product/passports',
        name : 'support_product_passports',
        component: () => import('@/views/Support/Product/Passports'),
        meta: {
            title: 'Паспорта автоматических систем',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support',
            divider_before: 'Файлы товаров',
            parent_subtitle: 'Файлы товаров',
        }
    },
    {
        path: '/support/product/instructions',
        name : 'support_product_instructions',
        component: () => import('@/views/Support/Product/Instructions'),
        meta: {
            title: 'Инструкции',
            roles: ['admin', 'pim_editor', 'pim_moderator', 'tech_expert'],
            parent: 'support',
            parent_subtitle: 'Файлы товаров',
        }
    },
    {
        path: '/support/product/certificates',
        name : 'support_product_certificates',
        component: () => import('@/views/Support/Product/Certificates'),
        meta: {
            title: 'Сертификаты',
            roles: ['admin', 'pim_editor', 'pim_moderator', '1c'],
            parent: 'support',
            parent_subtitle: 'Файлы товаров',
        }
    },
    {
        path: '/support/product/catalogs',
        name : 'support_product_catalogs',
        component: () => import('@/views/Support/Product/Catalogs'),
        meta: {
            title: 'Каталоги',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },
    {
        path: '/support/product/installdocs',
        name : 'support_product_installdocs',
        component: () => import('@/views/Support/Product/Installdocs'),
        meta: {
            title: 'Инструкции по установке',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },
    {
        path: '/support/product/projectdocs',
        name : 'support_product_projectdocs',
        component: () => import('@/views/Support/Product/Projectdocs'),
        meta: {
            title: 'Проектная документация',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },
    {
        path: '/support/product/presentations',
        name : 'support_product_presentations',
        component: () => import('@/views/Support/Product/Presentations'),
        meta: {
            title: 'Презентации',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },
    {
        path: '/support/product/video',
        name : 'support_product_video',
        component: () => import('@/views/Support/Product/Video'),
        meta: {
            title: 'Видео',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },
    
    
    
    {
        path: '/support/other/company_certificates',
        name : 'support_other_company_certificates',
        component: () => import('@/views/Support/Other/CompanyCertificates'),
        meta: {
            title: 'Сертификаты компании',
            roles: ['admin'],
            parent: 'support',
            divider_before: 'Прочие Файлы'
        }
    },
    {
        path: '/support/other/nocatalog_certificates',
        name : 'support_other_nocatalog_certificates',
        component: () => import('@/views/Support/Other/NoProductCertificates'),
        meta: {
            title: 'Сертификаты товаров вне каталога',
            roles: ['admin'],
            parent: 'support',
        }
    },
    {
        path: '/support/other/files',
        name : 'support_other_files',
        component: () => import('@/views/Support/Other/SiteFiles'),
        meta: {
            title: ' Прочие файлы',
            roles: ['admin', 'pim_editor', 'pim_moderator'],
            parent: 'support'
        }
    },

    {
        path: '/bpt',
        name : 'bpt',
        meta: {
            title: 'Закрытый раздел',
            roles: ['admin', 'files_admin'],
            icon: 'documentation'
        }
    },{
        path: '/bpt/category',
        name : 'bpt_category',
        component: () => import('@/views/Bpt/Category'),
        meta: {
            title: 'Категории',
            roles: ['admin', 'files_admin'],
            parent: 'bpt'
        }
    },{
        path: '/bpt/files',
        name : 'bpt_files',
        component: () => import('@/views/Bpt/Files'),
        meta: {
            title: 'Файлы',
            roles: ['admin', 'files_admin'],
            parent: 'bpt'
        }
    },{
        path: '/bpt/video',
        name : 'bpt_video',
        component: () => import('@/views/Bpt/Video'),
        meta: {
            title: 'Ссылки на видео',
            roles: ['admin', 'files_admin'],
            parent: 'bpt'
        }
    },

    {
        path: '/faq',
        name : 'faq',
        meta: {
            title: 'FAQ',
            roles: ['admin', 'marketolog', 'smm'],
            icon: 'documentation'
        }
    },
    {
        path: '/faq/categories',
        name : 'faq_categories',
        component: () => import('@/views/Site/FAQ/Category'),
        meta: {
            title: 'Категории',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'faq'
        }
    },
    {
        path: '/faq/questions',
        name : 'faq_questions',
        component: () => import('@/views/Site/FAQ/Question'),
        meta: {
            title: 'Вопросы',
            roles: ['admin', 'marketolog', 'smm'],
            parent: 'faq'
        }
    },

    {
        path: '/feedback',
        name : 'feedback',
        meta: {
            title: 'Пользователи',
            roles: ['admin', 'marketolog', '1c', 'director_op', 'smm', 'academy'],
            icon: 'documentation'
        }
    },{
        path: '/shop/users',
        name : 'shop_users',
        component: () => import('@/views/Shop/Users'),
        meta: {
            title: 'Пользователи',
            roles: ['admin', 'director_op', 'academy'],
            parent: 'feedback'
        }
    },
    {
        path: '/shop/orders',
        name : 'shop_orders',
        component: () => import('@/views/Shop/Orders'),
        meta: {
            title: 'Заказы',
            roles: ['admin'],
            parent: 'feedback'
        }
    },{
        path: '/feedback/subscribe',
        name : 'feedback_subscribe',
        component: () => import('@/views/Feedback/Subscribe'),
        meta: {
            title: 'Подписки',
            roles: ['admin', 'marketolog', '1c', 'smm'],
            parent: 'feedback'
        }
    },{
        path: '/feedback/request_category',
        name : 'request_category',
        component: () => import('@/views/Feedback/RequestCategory'),
        meta: {
            title: 'Темы вопросов',
            roles: ['admin'],
            parent: 'feedback'
        }
    },{
        path: '/feedback/request',
        name : 'request',
        component: () => import('@/views/Feedback/Request'),
        meta: {
            title: 'Вопросы обратной связи',
            roles: ['admin'],
            parent: 'feedback'
        }
    },{
        path: '/feedback/project',
        name : 'project',
        component: () => import('@/views/Feedback/Project'),
        meta: {
            title: 'Заказы на проекты',
            roles: ['admin'],
            parent: 'feedback'
        }
    },{
        path: '/feedback/lk-request',
        name : 'lk_request',
        component: () => import('@/views/Feedback/LkRequest'),
        meta: {
            title: 'Вопросы обратной связи ЛК',
            roles: ['admin'],
            parent: 'feedback'
        }
    },

    {
        path: '/academy',
        name : 'academy',
        meta: {
            title: 'Академия',
            roles: ['admin', 'academy', '1c'],
            icon: 'documentation'
        }
    },{
        path: '/academy/city',
        name : 'academy_city',
        component: () => import('@/views/Academy/City'),
        meta: {
            title: 'Учебные классы',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/trainer',
        name : 'academy_trainer',
        component: () => import('@/views/Academy/Trainer'),
        meta: {
            title: 'Тренеры',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/category',
        name : 'academy_category',
        component: () => import('@/views/Academy/Category'),
        meta: {
            title: 'Категории курсов',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/subcategory',
        name : 'academy_subcategory',
        component: () => import('@/views/Academy/Subcategory'),
        meta: {
            title: 'Подкатегории курсов',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },
    {
        path: '/academy/courses',
        name : 'academy_courses',
        component: () => import('@/views/Academy/Courses'),
        meta: {
            title: 'Курсы',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/courses-online',
        name : 'academy_courses_online',
        component: () => import('@/views/Academy/CoursesOnline'),
        meta: {
            title: 'Курсы Online',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/events',
        name : 'academy_events',
        component: () => import('@/views/Academy/Events'),
        meta: {
            title: 'Мероприятия',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy',
        }
    },{
        path: '/academy/certificate',
        name : 'academy_certificate',
        component: () => import('@/views/Academy/Certificates'),
        meta: {
            title: 'Сертификаты',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy'
        }
    },{
        path: '/academy/registrations',
        name : 'academy_registrations',
        component: () => import('@/views/Academy/Registrations'),
        meta: {
            title: 'Регистрации на тренинги',
            roles: ['admin', 'academy', '1c'],
            parent: 'academy',
        }
    },{
        path: '/academy/questions',
        name : 'academy_questions',
        component: () => import('@/views/Academy/Questions'),
        meta: {
            title: 'Заданные вопросы',
            roles: ['admin', 'academy'],
            parent: 'academy',
        }
    },{
        path: '/academy/information',
        name : 'academy_information',
        component: () => import('@/views/Academy/Information'),
        meta: {
            title: 'Сведения об ОД',
            roles: ['admin', 'academy'],
            parent: 'academy'
        }
    },{
        path: '/academy/ispring-users',
        name : 'academy_ispring_users',
        component: () => import('@/views/Academy/IspringUsers'),
        meta: {
            title: 'Пользователи iSpring',
            roles: ['admin', 'academy'],
            parent: 'academy'
        }
    },

    {
        path: '/came-club',
        name : 'cameclub',
        meta: {
            title: 'CAME Club',
            roles: ['admin', 'marketolog'],
            icon: 'documentation'
        }
    },{
        path: '/came-club/events',
        name : 'cameclub_events',
        component: () => import('@/views/Came Club/Events'),
        meta: {
            title: 'Мероприятия',
            roles: ['admin', 'marketolog'],
            parent: 'cameclub'
        }
    },{
        path: '/came-club/cities',
        name : 'cameclub_cities',
        component: () => import('@/views/Came Club/Cities'),
        meta: {
            title: 'Города проведения  мероприятий',
            roles: ['admin', 'marketolog'],
            parent: 'cameclub'
        }
    },{
        path: '/came-club/places',
        name : 'cameclub_places',
        component: () => import('@/views/Came Club/Places'),
        meta: {
            title: 'Места проведения  мероприятий',
            roles: ['admin', 'marketolog'],
            parent: 'cameclub'
        }
    },{
        path: '/came-club/teams',
        name : 'cameclub_teams',
        component: () => import('@/views/Came Club/Teams'),
        meta: {
            title: 'Команды',
            roles: ['admin', 'marketolog'],
            parent: 'cameclub'
        }
    },

    {
        path: '/seo',
        name : 'seo',
        meta: {
            title: 'SEO',
            roles: ['admin', 'seo'],
            icon: 'documentation'
        }
    },{
        path: '/seo/settings',
        name : 'seo_settings',
        component: () => import('@/views/Seo/Settings'),
        meta: {
            title: 'SEO настройки',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/redirects',
        name : 'seo_redirects',
        component: () => import('@/views/Seo/Redirects'),
        meta: {
            title: 'Редиректы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/pages',
        name : 'seo_pages',
        component: () => import('@/views/Seo/Records/Pages'),
        meta: {
            title: 'SEO Страницы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/landings',
        name : 'seo_landings',
        component: () => import('@/views/Seo/Records/Landings'),
        meta: {
            title: 'SEO Лендинги',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/articles',
        name : 'seo_articles',
        component: () => import('@/views/Seo/Records/Articles'),
        meta: {
            title: 'SEO Статьи',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/news',
        name : 'seo_news',
        component: () => import('@/views/Seo/Records/News'),
        meta: {
            title: 'SEO Новости',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/blog',
        name : 'seo_blog',
        component: () => import('@/views/Seo/Records/Blog'),
        meta: {
            title: 'SEO Блог',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/catalog_category',
        name : 'seo_catalog_category',
        component: () => import('@/views/Seo/Records/CatalogCategory'),
        meta: {
            title: 'SEO Категории каталога',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/catalog_products',
        name : 'seo_catalog_products',
        component: () => import('@/views/Seo/Records/CatalogProduct'),
        meta: {
            title: 'SEO Товары каталога',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/catalog_tags',
        name : 'seo_catalog_tags',
        component: () => import('@/views/Seo/Records/CatalogTags'),
        meta: {
            title: 'SEO Теги каталога',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/pricelists',
        name : 'seo_pricelists',
        component: () => import('@/views/Seo/Records/Pricelists'),
        meta: {
            title: 'SEO Прайс-листы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/projects',
        name : 'seo_projects',
        component: () => import('@/views/Seo/Records/Projects'),
        meta: {
            title: 'SEO Проекты',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/dealers_cities',
        name : 'seo_dealers_cities',
        component: () => import('@/views/Seo/Records/DealerCity'),
        meta: {
            title: 'SEO Города дилеров',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/academy_city',
        name : 'seo_academy_city',
        component: () => import('@/views/Seo/Records/AcademyCity'),
        meta: {
            title: 'SEO Академия, Учебные классы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/academy_courses',
        name : 'seo_academy_courses',
        component: () => import('@/views/Seo/Records/AcademyCourses'),
        meta: {
            title: 'SEO Академия, Курсы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/academy_trainer',
        name : 'seo_academy_trainer',
        component: () => import('@/views/Seo/Records/AcademyTrainers'),
        meta: {
            title: 'SEO Академия, Тренеры',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },{
        path: '/seo/academy_online_courses',
        name : 'seo_academy_online_courses',
        component: () => import('@/views/Seo/Records/AcademyOnlineCourses'),
        meta: {
            title: 'SEO Академия, Online Курсы',
            roles: ['admin', 'seo'],
            parent: 'seo'
        }
    },

    {
        path: '/site_settings',
        name : 'site_settings',
        component: () => import('@/views/Administration/Settings'),
        meta: {
            title: 'Настройки сайта',
            roles: ['admin', 'seo'],
            icon: 'documentation'
        }
    },

    {
        path: '/app_config',
        name : 'app_config',
        component: () => import('@/views/Administration/AppConfig'),
        meta: {
            title: 'Конфигурация',
            roles: ['developer'],
            icon: 'documentation'
        }
    },

    {
        path: '/administration',
        name : 'administration',
        meta: {
            title: 'Администрирование',
            roles: ['admin','1c'],
            icon: 'documentation'
        }
    },{
        path: '/administration/users',
        name : 'users',
        component: () => import('@/views/Administration/Users'),
        meta: {
            title: 'Пользователи системы',
            roles: ['admin'],
            parent: 'administration'
        }
    },{
        path: '/administration/pim_moderators',
        name : 'pim_moderators',
        component: () => import('@/views/Administration/PimModerators'),
        meta: {
            title: 'Права модераторов PIM',
            roles: ['admin'],
            parent: 'administration'
        }
    },{
        path: '/administration/pim_attributes',
        name : 'pim_attributes',
        component: () => import('@/views/Administration/PimAttributes'),
        meta: {
            title: 'Атрибуты товаров PIM',
            roles: ['admin'],
            parent: 'administration'
        }
    },{
        path: '/administration/dealers_attributes',
        name : 'dealers_attributes',
        component: () => import('@/views/Administration/DealersAttributes'),
        meta: {
            title: 'Атрибуты дилеров',
            roles: ['admin'],
            parent: 'administration'
        }
    },{
        path: '/administration/projects_attributes',
        name : 'projects_attributes',
        component: () => import('@/views/Administration/ProjectsAttributes'),
        meta: {
            title: 'Атрибуты проектов',
            roles: ['admin'],
            parent: 'administration'
        }
    },{
        path: '/administration/emailsettings',
        name : 'emailsettings',
        component: () => import('@/views/Administration/EmailSettings'),
        meta: {
            title: 'Настройки email уведомлений',
            roles: ['admin'],
            parent: 'administration'
        }
    // },{
        // path: '/administration/cameusers',
        // name : 'cameusers',
        // component: () => import('@/views/Administration/CameUsers'),
        // meta: {
        //     title: 'Сотрудники Came',
        //     roles: ['admin'],
        //     parent: 'administration'
        // }
    },{
        path: '/administration/dealers_regions',
        name : 'administration_dealers_regions',
        component: () => import('@/views/Administration/DealersRegions'),
        meta: {
            title: 'Бизнес регионы',
            roles: ['admin'],
            parent: 'administration'
        }
    },
    {
        path: '/administration/filemanager',
        name : 'filemanager',
        component: () => import('@/views/Administration/FileManager'),
        meta: {
            title: 'Файловый менеджер',
            roles: ['admin','1c'],
            parent: 'administration'
        }
    },{
        path: '/administration/filemanager/:entity',
        name : 'filemanager_folder',
        component: () => import('@/views/Administration/FileManager'),
        meta: {
            title: 'Файловый менеджер',
            roles: ['admin','1c'],
            parent: 'administration'
        },
        hidden   : true,
    },

    {
        path: '/administration-rest',
        name : 'administration_rest',
        meta: {
            title: 'Синхронизация с 1С',
            roles: ['admin','1c'],
            icon: 'documentation'
        }
    },{
        path: '/administration-rest/restusers',
        name : 'restusers',
        component: () => import('@/views/Administration/RestUsers'),
        meta: {
            title: 'Пользователи REST',
            roles: ['admin'],
            parent: 'administration_rest'
        }
    },{
        path: '/administration-rest/restlog',
        name : 'restlog',
        component: () => import('@/views/Administration/RestLog'),
        meta: {
            title: 'Запросы к REST от 1С',
            roles: ['admin','1c'],
            parent: 'administration_rest'
        }
    },

    {
        path: '/administration-amo',
        name : 'administration_amo',
        meta: {
            title: 'Синхронизация с AmoCrm',
            roles: ['admin'],
            icon: 'documentation'
        }
    },{
        path: '/administration-amo/amousers',
        name : 'amousers',
        component: () => import('@/views/Administration/AmoUsers'),
        meta: {
            title: 'Пользователи AmoCrm',
            roles: ['admin'],
            parent: 'administration_amo'
        }
    },{
        path: '/administration-amo/sendlog',
        name : 'amo_sendlog',
        component: () => import('@/views/Administration/AmoGetLog'),
        meta: {
            title: 'Запросы от AmoCrm',
            roles: ['admin','1c'],
            parent: 'administration_amo'
        }
    },{
        path: '/administration-amo/getlog',
        name : 'amo_getlog',
        component: () => import('@/views/Administration/AmoSendLog'),
        meta: {
            title: 'Запросы к AmoCrm',
            roles: ['admin','1c'],
            parent: 'administration_amo'
        }
    },


    {
        path: '/docs',
        name : 'docs',
        meta: {
            title: 'Документация и справка',
            roles: ['admin','1c','marketolog'],
            icon: 'documentation'
        }
    },
    {
        path: '/docs/restapi',
        name : 'docs_restapi',
        url: 'http://docs.nixteam.ru/pub/zj0Cyp/doc/rest',
        meta: {
            title: 'REST API',
            roles: ['admin','1c','marketolog'],
            parent: 'docs'
        }
    },{
        path: '/docs/pages',
        name : 'docs_pages',
        component: () => import('@/views/Documentation/Pages'),
        meta: {
            title: 'Страницы сайта',
            roles: ['admin','marketolog'],
            parent: 'docs'
        }
    },{
        path: '/docs/contacts',
        name : 'docs_contacts',
        component: () => import('@/views/Documentation/Contacts'),
        meta: {
            title: 'Контакты',
            roles: ['admin','marketolog'],
            parent: 'docs'
        }
    },{
        path: '/docs/dealers',
        name : 'docs_dealers',
        component: () => import('@/views/Documentation/Dealers'),
        meta: {
            title: 'Поиск по дилерам',
            roles: ['admin','marketolog'],
            parent: 'docs'
        }
    },{
        path: '/docs/kp_parking',
        name : 'docs_kp_parking',
        component: () => import('@/views/Documentation/Parking_Kp'),
        meta: {
            title: 'Калькулятор парковок',
            roles: ['admin','marketolog'],
            parent: 'docs'
        }
    },{
        path: '/docs/pricing',
        name : 'docs_pricing',
        component: () => import('@/views/Documentation/Pricing'),
        meta: {
            title: 'Ценообразование',
            roles: ['admin','marketolog'],
            parent: 'docs'
        }
    },
    
    {
        path: '/changelog',
        name : 'changelog',
        component: () => import('@/views/Changelog'),
        meta: {
            title: 'История изменений сайта',
            roles: ['admin','marketolog'],
            icon: 'documentation'
        }
    },
    
    {
        path: '/auth',
        name : 'auth_by_hash',
        component: () => import('@/views/AuthByHash'),
        meta: {},
        hidden   : true,
    },
]

export default new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: routerMap,
    mode: 'history'
})
